<mat-sidenav-container class="sidenav-container">
	<mat-sidenav #sidenav (click)="sidenav.close()" class="sidenav-nav" mode="over">
		<div class="sidenav-nav-header">
			<div class="header">
				<div (click)="sidenavDebug()" class="app-version">
					{{ APP_VERSION }}
				</div>
				<div class="user">
					{{ user?.firstName }}
				</div>
				<div class="region">
					{{ region?.name }}
				</div>
			</div>
		</div>
		<mat-list>
			<mat-list-item [routerLink]="['/user/dashboard']" matRipple>
				<mat-icon color="primary">view_agenda</mat-icon>
				Dashboard
			</mat-list-item>

			<mat-divider class="divider-large"></mat-divider>

			<mat-list-item [routerLink]="['/utility/orders']" matRipple>
				<mat-icon color="primary">list</mat-icon>
				Commandes
			</mat-list-item>
			<mat-divider></mat-divider>
			<mat-list-item [routerLink]="['/utility/operations']" matRipple>
				<mat-icon color="primary">business</mat-icon>
				Opérations
			</mat-list-item>

			<mat-divider class="divider-large"></mat-divider>

			<mat-list-item [routerLink]="['/run/waste']" matRipple>
				<mat-icon color="primary">delete_sweep</mat-icon>
				Retour déchetterie
			</mat-list-item>
			<mat-divider></mat-divider>
			<mat-list-item [routerLink]="['/run/provider']" matRipple>
				<mat-icon color="primary">extension</mat-icon>
				Retour prestataire
			</mat-list-item>

			<mat-divider class="divider-large"></mat-divider>

			<mat-list-item [routerLink]="['/run/handler']" matRipple>
				<mat-icon color="primary">timer</mat-icon>
				Retour manutention
			</mat-list-item>

			<mat-divider></mat-divider>
			<mat-list-item (click)="openEquipment()" matRipple>
				<mat-icon color="primary">handyman</mat-icon>
				Demande de matériel
			</mat-list-item>
			<mat-divider></mat-divider>
			<mat-list-item (click)="openExpenseReportForm()" matRipple>
				<mat-icon color="primary">note_add</mat-icon>
				Note de frais
			</mat-list-item>

			<mat-divider class="divider-large"></mat-divider>

			<mat-list-item (click)="openSimulator()" matRipple>
				<mat-icon color="primary">euro</mat-icon>
				Simulateur
			</mat-list-item>
		</mat-list>
		<div class="sidenav-nav-separator"></div>
		<mat-list>
			<mat-divider></mat-divider>
			<mat-list-item [routerLink]="['/user/logout']" matRipple>
				<mat-icon color="primary">close</mat-icon>
				Déconnexion
			</mat-list-item>
		</mat-list>
	</mat-sidenav>
	<mat-sidenav-content class="sidenav-content">
		<div *ngIf="ui.header.toolbarShow" class="toolbar-box">
			<mat-toolbar class="toolbar mat-elevation-z3" color="primary">
				<mat-toolbar-row>
					<button (click)="sidenav.open()" class="menu-button" mat-icon-button>
						<mat-icon>menu</mat-icon>
					</button>
					<span>
							{{ ui.header.toolbarTitle || 'Les Ripeurs - Opérateur' }}
						</span>
				</mat-toolbar-row>
			</mat-toolbar>
		</div>
		<mat-progress-bar
			[class.loader-bar-toolbar]="ui.header.toolbarShow"
			[style.display]="ui.header.loaderBarShow ? 'block' : 'none'"
			class="loader-bar"
			mode="indeterminate"
		/>
		<router-outlet #router/>
	</mat-sidenav-content>
</mat-sidenav-container>
