import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


interface CommonDialogMessageData {
	title?: string;
	content?: string;
	contentIframeUrl?: string;
	contentLoader?: boolean;
	contentIcon?: string;
	buttons?: Array<CommonDialogMessageDataButton>;
	close?: boolean;
	debug?: () => void;
	debugId?: string;
}

interface CommonDialogMessageDataButton {
	title: string;
	iconLeft?: string;
	iconRight?: string;
	click?: () => void;
}

@Component({
	templateUrl: './message.component.html',
	styleUrls: ['./message.component.scss'],
})
export class CommonDialogMessage {
	constructor(
		private dialogRef: MatDialogRef<CommonDialogMessage>,
		@Inject(MAT_DIALOG_DATA) public data: CommonDialogMessageData | null = null,
	) {}

	public close() {
		this.dialogRef.close();
	}

	public buttonClick(button: CommonDialogMessageDataButton) {
		if (button.click !== undefined) {
			button.click();
		} else {
			this.close();
		}
	}

	public debugClick() {
		if (this.data !== null
			&& this.data.debug !== undefined) {
			this.data.debug();
		}
	}
}
