import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '~app/guards/auth.guard';
import { NavGuard } from '~app/guards/nav.guard';

import { Error404Component } from './error/404/404.component';
import { UserLoginComponent } from './user/login/login.component';


const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: '/user/dashboard',
	},
	{
		path: 'user/login',
		component: UserLoginComponent,
		canActivate: [NavGuard],
	},
	{
		path: 'user',
		loadChildren: () => import('./user/user.module').then(m => m.UserModule),
		canActivateChild: [NavGuard, AuthGuard],
	},
	{
		path: 'run',
		loadChildren: () => import('./run/run.module').then(m => m.RunModule),
		canActivateChild: [NavGuard, AuthGuard],
	},
	{
		path: 'utility',
		loadChildren: () => import('./utility/utility.module').then(m => m.RunModule),
		canActivateChild: [NavGuard, AuthGuard],
	},
	{
		path: '**',
		component: Error404Component,
		canActivate: [NavGuard],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { enableTracing: false })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
