import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'textgroup' })
export class RipTextgroupPipe implements PipeTransform {
	public transform(
		value: string,
		regexString: string,
		groupClass: string = 'rip-textgroup',
	) {
		const match = value.match(new RegExp(regexString));

		if (match === null) {
			return value;
		} else {
			let valueNew = '';
			for (let groupNumber = 1; groupNumber < match.length; groupNumber++) {
				valueNew += `<span class="${groupClass} ${groupClass}-${groupNumber}">${match[groupNumber]}</span>`;
			}
			return valueNew;
		}
	}
}
