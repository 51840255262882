import { UntypedFormControl, UntypedFormGroup, Validators, } from '@angular/forms';


export class UserForm {
	public login() {
		return new UntypedFormGroup({
			email: new UntypedFormControl('', [
				Validators.required,
				Validators.email,
			]),
			password: new UntypedFormControl('', [
				Validators.required,
			]),
		});
	}
}
