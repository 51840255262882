import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'replace' })
export class ReplacePipe implements PipeTransform {
	public transform(
		value: string,
		regex: string,
		replace: string,
	) {
		return value.replace(new RegExp(regex, 'g'), replace);
	}
}
