import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, finalize, Observable, tap } from 'rxjs';

import { environment } from 'src/environments/environment';
import { DebugService } from '~app/services/debug.service';

import { DialogService } from '~app/services/dialog.service';
import { UiService } from '~app/services/ui.service';

interface RequestOptions {
	errorCatch?: boolean;
	headers?: { [k: string]: string | string[] };
	observable?: boolean;
	reportProgress?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ApiService {
	private readonly apiUrl: string;

	constructor(
		private readonly _debug: DebugService,
		private readonly _dialog: DialogService,
		private readonly _http: HttpClient,
		private readonly _ui: UiService,
	) { this.apiUrl = environment.app.api.url; }

	public get<T>(url: string, params?: { [key: string]: string }, options?: RequestOptions): Observable<T> {
		this._ui.header.set({ loaderBarShow: true });
		const headers = this.setHeaders(options?.headers);

		return this._http.get<T>(`${this.apiUrl}${url}`, { headers, params }).pipe(
			tap(() => this._ui.header.set({ loaderBarShow: true })),
			finalize(() => this._ui.header.set({ loaderBarShow: false })),
			catchError((error) => this.handleError<T>(params, error, options?.errorCatch, headers)),
		);
	}

	public post<T>(url: string, body: any, params?: { [key: string]: string }, options?: RequestOptions): Observable<T> {
		this._ui.header.set({ loaderBarShow: true });
		const headers = this.setHeaders(options?.headers);

		return this._http.post<T>(`${this.apiUrl}${url}`, body, { headers, params }).pipe(
			tap(() => this._ui.header.set({ loaderBarShow: true })),
			finalize(() => this._ui.header.set({ loaderBarShow: false })),
			catchError((error) => this.handleError<T>(body, error, options?.errorCatch, headers)),
		);
	}

	public put<T>(url: string, body: any, params?: { [key: string]: string }, options?: RequestOptions): Observable<T> {
		this._ui.header.set({ loaderBarShow: true });
		const headers = this.setHeaders(options?.headers);

		return this._http.put<T>(`${this.apiUrl}${url}`, body, { headers, params }).pipe(
			tap(() => this._ui.header.set({ loaderBarShow: true })),
			finalize(() => this._ui.header.set({ loaderBarShow: false })),
			catchError((error) => this.handleError<T>(body, error, options?.errorCatch, headers)),
		);
	}

	private setHeaders(headers?: { [key: string]: string | string[] }): HttpHeaders {
		const ripID = Math.round(Math.random() * 1000000000)
			.toString(36)
			.toUpperCase()
			.replace(/[01OIL]/gi, '8');
		if (!headers) return new HttpHeaders({ 'X-Ripeurs-Rid': ripID });
		return new HttpHeaders({ ...headers, 'X-Ripeurs-Rid': ripID });
	}

	private handleError<T>(
		request: HttpRequest<T> | Record<string, string> | any,
		error: any, errorCatch = false,
		headers: HttpHeaders
	): Observable<T> {
		if (errorCatch) {
			this._dialog.openMessage({
				title: `Problème de connexion`,
				content: `Vérifiez votre connexion internet ou recommencer plus tard.`,
				debugId: `RID-V1: ${headers.get('X-Ripeurs-Rid')}`,
				buttons: [{ title: `Ok` }],
				debug: () => {
					const requestDebug = request;
					if (requestDebug.body instanceof FormData) (<any>requestDebug).body = 'FormData';
					this._debug.mailDebug('Http global', requestDebug);
				},
			});
		}


		console.error('An error occurred:', error);
		throw error;
	}
}
