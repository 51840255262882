import { Pipe, PipeTransform } from '@angular/core';

import { Duration } from 'luxon';


@Pipe({ name: 'RipLuxonDurationPipe' })
export class RipLuxonDurationPipe implements PipeTransform {
	public transform(
		duration: Duration,
		format: string,
	) {
		return duration.toFormat(format);
	}
}
