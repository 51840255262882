import { RegionType } from '~type/service/geo/region';


export enum UserDataAccessLevel {
	Normal = 'Normal',
	Admin = 'Admin',
	Operator = 'Operator',
}

export interface UserData {
	id: string;
	accessLevel: UserDataAccessLevel;
	email: string;
	firstName: string;
	lastName: string;
	phone: string;
	addressCompany?: string;
	administrativeId?: string;
	cityCompany?: string;
	isPartnerClient?: Boolean;
	isSubscribed?: Boolean;
	nameCompany?: string;
	platform?: string;
	postalCodeCompany?: string;
	stripeId: string;
	referral?: string;
	referralStore?: string;
	region?: string;
	resetExpires?: Date;
	resetToken?: string;
	createdAt: Date;
	updatedAt: Date;
	meta: { regions: RegionType.Region[] };
}
