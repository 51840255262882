import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, filter, switchMap, tap } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class UiService {
	public header: UiHeader;
	public content: UiContent;

	constructor(
		private readonly _router: Router,
		private readonly _translate: TranslateService,
	) {

		this.header = new UiHeader({
			toolbarTitle: null,
			toolbarShow: false,
			loaderBarShow: false,
		});
		this.content = new UiContent({ scroll: [0, 0] });

		this.header.subject.pipe(
			filter((header) => Array.isArray(header.toolbarTitle)),
			switchMap((header) => {
				const [key, interpolateParams]: any = header.toolbarTitle;
				return this._translate.get(key, interpolateParams).pipe(
					tap((toolbarTitle) => this.header.set({ toolbarTitle })),
				);
			}),
		).subscribe();

		this._router.events.pipe(
			filter((event => event instanceof NavigationStart || event instanceof NavigationEnd)),
		).subscribe((event) => {
			const isNavigationStart = event instanceof NavigationStart;

			if (isNavigationStart) this.header.set({ toolbarTitle: null });
			this.header.set({ loaderBarShow: isNavigationStart });
		});
	}
}

abstract class UiElement<T> {
	public subject: BehaviorSubject<T>;
	private _data: T;

	constructor(defaultData: T) {
		this._data = defaultData;
		this.subject = new BehaviorSubject<T>(this._data);
	}

	public set(data: Partial<T>): void {
		this._data = Object.assign({}, this._data, data);
		this.subject.next(this._data);
	}

	public get(): T { return this._data; }
}

class UiHeader extends UiElement<{
	toolbarTitle?: string | [string] | [string, { [k: string]: any }] | null,
	toolbarShow?: boolean,
	loaderBarShow?: boolean,
}> {}

class UiContent extends UiElement<{
	scroll?: [number, number],
}> {}
