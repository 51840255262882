import { RunWasteType } from '~app/types/run';
import { RegionType } from '~type/service/geo/region';
import { OperationType } from '~type/service/utility/operation';


type NotDone = Omit<{ [k in keyof typeof OperationType.Status]: { icon: string, title: string } }, 'DONE'>;
export type OperationStepsType = NotDone & { [k in 'DONE']?: never };

export interface RegionData {
	readonly iconRight: 'adjust' | 'hdr_weak';
	readonly id: string;
	readonly title: string;
}

export interface RegionsSimulator {
	readonly FR_IDF: readonly RegionData[];
	readonly FR_LYON: readonly RegionData[];
}

export const materialHandlers = [
	`AMOUR DIT ZERROUK ABDELDJALIL`,
	`CHEURFI HAMZA`,
	`COMPOINT THOMAS`,
	`HARIZI KHALED`,
	`HILARIC JEAN CHRISTOPHE`,
	`KARAMOKO ZOUMANA`,
	`KHELLAF AREZKI`,
	`LEIGNEL MATTHIEU`,
	`MAKHLOUF SOUHEIB`,
	`MOULY LUCAS`,
	`OUHALA ABDELKADER`,
	`SIAMER SAMY`,
	`TOURE ADAMA`,
];
export const trucks = [
	'CAMION 1',
	'CAMION 2',
	'CAMION 3',
	'CAMION 4',
	'CAMION 5',
	'CAMION 6',
	'CAMION 7',
	'CAMION 8',
	'CAMION 9',
	'CAMION 10',
	'CAMION 11',
	'CAMION 12',
	'CAMION 13',
	'CAMION 14',
	'CAMION 15',
	'CAMION 16',
	'AUTRE',
];
export const landfills = [
	[`DERICHEBOURG IVRY`, RunWasteType.WEIGHT],
	[`DERICHEBOURG NANTERRE`, RunWasteType.WEIGHT],
	[`DERICHEBOURG NOISY`, RunWasteType.WEIGHT],
	[`DERICHEBOURG CHATILLON`, RunWasteType.WEIGHT],
	[`DERICHEBOURG BONNEUIL`, RunWasteType.WEIGHT],
	[`DERICHEBOURG CORBEIL-ESSONNES`, RunWasteType.WEIGHT],
	[`DERICHEBOURG ARGENTEUIL`, RunWasteType.WEIGHT],
	[`DERICHEBOURG CHELLES`, RunWasteType.WEIGHT],
	[`DERICHEBOURG LIMAY`, RunWasteType.WEIGHT],
	[`DERICHEBOURG NEMOUR`, RunWasteType.WEIGHT],
	[`DERICHEBOURG GENNEVILLIERS`, RunWasteType.WEIGHT],
	[`DERICHEBOURG LA COURNEUVE`, RunWasteType.WEIGHT],
	[`PAPREC COURNEUVE`, RunWasteType.WEIGHT],
	[`VAREA IVRY`, RunWasteType.WEIGHT],
	[`PICHETA NANTERRE`, RunWasteType.WEIGHT],
	[`PICHETA PIERRELAYE`, RunWasteType.WEIGHT],
	[`PICHETA LIMAY`, RunWasteType.WEIGHT],
	[`PICHETA TRIEL SUR SEINE`, RunWasteType.WEIGHT],
	[`PICHETA ACHERES`, RunWasteType.WEIGHT],
	[`SMS ECOTRI L'HAY-LES-ROSES`, RunWasteType.WEIGHT],
	[`COSSON PLATEFORME ECOTRI LA COURNEUVE`, RunWasteType.WEIGHT],
	[`SOLARZ LE BOURGET`, RunWasteType.WEIGHT],
	[`LUXO BENNES 93450 SAINT DENIS`, RunWasteType.WEIGHT],
	[`LUXO BENNES MASSY`, RunWasteType.WEIGHT],
	[`LUXO BENNES Vitry`, RunWasteType.WEIGHT],
	[`LUXO BENNES PONT CARRE`, RunWasteType.WEIGHT],
	[`LUSOFER VITRY`, RunWasteType.WEIGHT],
	[`SADLOC VILLENEUVE LE ROI`, RunWasteType.WEIGHT],
	[`BUTY SERVICES - LYON`, RunWasteType.WEIGHT],
	[`SERFIM Lyon 7`, RunWasteType.WEIGHT],
];
export const providerNames = ['GIRALIFT', 'SABRI HLB', 'PZ TRANSPORT', 'GKTDE', 'AUTRE'];
export const operationSteps: OperationStepsType = {
	[OperationType.Status.STARTED]: {
		icon: 'phone',
		title: `Cordonnées client`,
	},
	[OperationType.Status.CUSTOMER_CONTACTED]: {
		icon: 'timer',
		title: `Temps de trajet`,
	},
	[OperationType.Status.TRAVEL_TIME_ADDED]: {
		icon: 'photo_camera',
		title: `Photos avant l'opération`,
	},
	[OperationType.Status.OPERATION_PICTURES_TAKED_BEFORE]: {
		icon: 'assignment_turned_in',
		title: `Conformité de l'opération`,
	},
	[OperationType.Status.OPERATION_FORECAST_CHECKED]: {
		icon: 'photo_camera',
		title: `Photos après l'opération`,
	},
	[OperationType.Status.OPERATION_PICTURES_TAKED_AFTER_OPTIONAL]: {
		icon: 'timeline',
		title: `Ajustement au réel`,
	},
	[OperationType.Status.OPERATION_CONTENT_ADDED]: {
		icon: 'euro',
		title: `Calcul du prix`,
	},
	[OperationType.Status.OPERATION_PRICE_ADDED]: {
		icon: 'receipt',
		title: `Validation du reçu`,
	},
	[OperationType.Status.OPERATION_VALIDATED]: {
		icon: 'photo_camera',
		title: `Photos après l'opération`,
	},
	[OperationType.Status.OPERATION_PICTURES_TAKED_AFTER]: {
		icon: 'photo_camera',
		title: `Photos du camion`,
	},
	[OperationType.Status.TRUCK_PICTURES_TAKED]: {
		icon: 'notes',
		title: `Détails interne`,
	},
	[OperationType.Status.INTERNAL_META_ADDED]: {
		icon: 'timer',
		title: `Temps de l'opération`,
	},
	[OperationType.Status.OPERATION_TIME_ADDED]: {
		icon: 'check',
		title: `Fin de l'opération`,
	},
};
export const simulatorByZone: RegionsSimulator = {
	FR_IDF: [
		{
			iconRight: 'adjust',
			title: 'Paris et Intra Francilienne',
			id: 'SI-8Z8DKE',
		},
		{
			iconRight: 'hdr_weak',
			title: 'Hors Francilienne',
			id: 'SI-8Z8DTS',
		},
		{
			iconRight: 'adjust',
			title: '[REP] Paris et Intra Franci.',
			id: 'SI-925AKX',
		},
		{
			iconRight: 'hdr_weak',
			title: '[REP] Hors Francilienne',
			id: 'SI-925AFY',
		},
	],
	FR_LYON: [
		{
			iconRight: 'adjust',
			title: 'Lyon et petite couronne',
			id: 'SI-96S9DX',
		},
		{
			iconRight: 'hdr_weak',
			title: 'Grand Lyon',
			id: 'SI-96S9KF',
		},
		{
			iconRight: 'adjust',
			title: '[REP] Lyon et petite couronne',
			id: 'SI-96S9RY',
		},
		{
			iconRight: 'hdr_weak',
			title: '[REP] Grand Lyon',
			id: 'SI-96S9LU',
		},
	],
} as const;

export const metaRegions: RegionType.Region[] = [
	{
		id: 'FR_IDF',
		name: 'Île-de-France',
		config: {
			timezone: 'Europe/Paris',
			country: 'FR',
			locale: 'fr-FR',
			currency: 'EUR',
			vat: 20.0,
		},
	},
	{
		id: 'FR_LYON',
		name: 'Lyon',
		config: {
			timezone: 'Europe/Paris',
			country: 'FR',
			locale: 'fr-FR',
			currency: 'EUR',
			vat: 20.0,
		},
	},
	{ // Région de test
		id: 'TEST',
		name: 'Test (Greater London)',
		config: {
			timezone: 'Europe/London',
			country: 'GB',
			locale: 'en-GB',
			currency: 'GBP',
			vat: 10.0,
		},
	},
];
