import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { of } from 'rxjs';

import * as PACKAGE_JSON from 'src/../package.json';
import { environment } from 'src/environments/environment';

import { DialogService } from './dialog.service';
import { UserService } from './user.service';

const packageJson = JSON.stringify(PACKAGE_JSON);

@Injectable({ providedIn: 'root' })
export class DebugService {

	constructor(
		private readonly _dialog: DialogService,
		private readonly _injector: Injector,
		private readonly _router: Router,
	) {}

	private __user: UserService | null = null;

	private get _user(): UserService {
		return this.__user || (this.__user = this._injector.get(UserService));
	}

	public dialogDebug() {
		const dialogDebug = this._dialog.openMessage({
			title: `Debug`,
			content: `
				App version : ${JSON.parse(packageJson).version}
				<hr>
				User Token :
				<hr>
				<pre><code>${JSON.stringify(this._user.getToken(), null, '  ')}</code></pre>
				<hr>
				User Data :
				<hr>
				<pre><code>${JSON.stringify(this._user.getData(), null, '  ')}</code></pre>
				<hr>
				App Config :
				<hr>
				<pre><code>${JSON.stringify(environment, null, '  ')}</code></pre>
				<hr>
				App URL :
				<hr>
				<pre><code>${JSON.stringify(location.pathname)}</code></pre>
				<hr>
			`,
			buttons: [
				{
					title: `Go to`,
					click: () => {
						const url = prompt('URL:', '/');
						if (url !== null) {
							dialogDebug.close();
							of(this._router.navigateByUrl(url));
						}
					},
				},
				{
					title: `Reload app (destroy cache)`,
					click: () => {
						caches.keys().then((names) => {
							return Promise.all(names.map((name) => {
								return caches.delete(name);
							}));
						}).then(() => {
							location.reload();
						});
					},
				},
				{
					title: `Reload app`,
					click: () => {
						location.reload();
					},
				},
				{
					title: `Close`,
				},
			],
		}, {
			maxWidth: '95vw',
		});
	}

	mailDebug(
		subject: string = '',
		data: any = null,
	) {
		location.href = `mailto:dev+operator-app-debug@lesripeurs.com`
			+ `?subject=` + encodeURIComponent(`[OPERATOR-APP][` + environment.env + `][DEBUG] ` + subject)
			+ `&body=` + encodeURIComponent(`@@@ENVIRONMENT:` + JSON.stringify(environment) + `@@@DATA:` + JSON.stringify(data));
	}
}
