import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'ripMinute2time' })
export class RipMinute2timePipe implements PipeTransform {
	public transform(minute: number) {
		const hour = Math.floor(minute / 60);
		minute = minute - hour * 60;
		return hour + 'h' + (minute < 10 ? '0' : '') + minute;
	}
}
