import { Injectable } from '@angular/core';

import { catchError, map, Observable, of, Subject, tap } from 'rxjs';

import { LocalStorage } from '~app/common/decorator/local-storage.decorator';
import { UtService } from '~app/services/ut.service';
import { RegionType } from '~type/service/geo/region';

import { UserData, UserDataAccessLevel } from '../types/user';
import { ApiService } from './api.service';


@Injectable({ providedIn: 'root' })
export class UserService {
	public onUpdate = new Subject<{
		logged: boolean,
		user: UserData | null,
		region: RegionType.Region | null,
	}>();
	@LocalStorage('user_token') private token: string | null | undefined;
	private region: RegionType.Region | null = null;

	constructor(
		private readonly _api: ApiService,
		private readonly _ut: UtService,
	) {}

	private _data: UserData | null = null;

	private get data() { return this._data; }

	private set data(data) {
		if (data !== null) {
			if (data.meta === undefined) {
				data.meta = { regions: [] };
			}
			// @TODO Temporaire à importer du serveur
			if (!data.meta?.regions?.length) { data.meta.regions = this._ut.getMetaRegions(); }
			this.region = data.meta.regions.find(({ id }) => id === data.region) || data.meta.regions[0];
		}
		this._data = data;
		this.onUpdate.next({
			logged: this.isLogged(),
			user: this._data,
			region: this.region,
		});
	}

	public getToken(): string | null | undefined { return this.token; }

	public getData(): UserData | null { return this.data; }

	public getRegion(): RegionType.Region | null { return this.region; }

	public loggedGetRegion(): RegionType.Region {
		if (this.region === null) { throw new Error('User not logged'); }
		return this.region;
	}

	public isLogged(): boolean { return this.data !== null; }

	public login(
		email: string,
		password: string,
	): Observable<boolean> {
		return this._api.post(
			'auth/login',
			{ email, password, accessLevels: [UserDataAccessLevel.Operator] },
			{},
			{ errorCatch: false },
		).pipe(
			tap(({ user, token }: any) => {
				this.data = user;
				this.token = token;
			}),
			map(({ error }: any) => (!error)),
			catchError(() => of(false)),
		);
	}

	public loginToken(token: string): Observable<boolean> {
		return this._api.get('me', {}, {
			headers: { 'Authorization': token },
			errorCatch: false,
		}).pipe(
			map((data: any) => {
				if (data === null) return false;
				this.data = data;
				this.token = token;
				return true;
			}),
			catchError(() => of(false)),
		);
	}

	public logout(): void {
		this.data = null;
		this.token = null;
	}
}
