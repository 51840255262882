import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Pipe({ name: 'sanitizeHtml' })
export class SanitizeHtmlPipe implements PipeTransform {
	constructor(private readonly _domSanitizer: DomSanitizer) {}

	public transform(html: string): SafeHtml {
		return this._domSanitizer.bypassSecurityTrustHtml(html);
	}
}
