import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from './common/common.module';
import { Error404Component } from './error/404/404.component';
import { UserLoginComponent } from './user/login/login.component';

registerLocaleData(localeFr, 'fr-FR');
registerLocaleData(localeEn, 'en-GB');

const modules = [
	AppRoutingModule,
	BrowserAnimationsModule,
	BrowserModule,
	CommonModule,
	HttpClientModule,
	ServiceWorkerModule.register('ngsw-worker.js'),
	TranslateModule.forRoot({
		loader: {
			deps: [HttpClient],
			provide: TranslateLoader,
			useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
		},
	}),
];

const components = [
	AppComponent,
	Error404Component,
	UserLoginComponent,
];

const matProviders = [
	{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
];

@NgModule({
	declarations: [...components],
	imports: [...modules],
	providers: [
		...matProviders,
		{ provide: LOCALE_ID, useValue: 'fr-FR' },
		{
			provide: SwRegistrationOptions,
			useFactory: () => ({
				enabled: environment.env !== 'DEVELOPMENT',
				registrationStrategy: 'registerWhenStable:5000',
			}),
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
