export enum RunType {
	OPERATION = 'OPERATION',
	WASTE = 'WASTE',
}

export enum RunPaymentMethod {
	CB_APP = 'CB_APP',
	CASH_RECU = 'CASH_RECU',
	CHEQUE_RECU = 'CHEQUE_RECU',
	CHEQUE_NON_RECU = 'CHEQUE_NON_RECU',
	VIREMENT = 'VIREMENT',
	VIREMENT_APRES_RECEPTION = 'VIREMENT_APRES_RECEPTION',
	VIREMENT_AUTO = 'VIREMENT_AUTO',
	JE_NE_SAIS_PAS = 'JE_NE_SAIS_PAS',
}


export enum RunOperationPlace {
	TROTTOIR = 'TROTTOIR',
	RDC = 'RDC',
	ETAGE_AVEC_ASCENSEUR = 'ETAGE_AVEC_ASCENSEUR',
	ETAGE_SANS_ASCENSEUR = 'ETAGE_SANS_ASCENSEUR',
	SELF_HANDLING = 'SELF_HANDLING',
}

export enum RunOperationReductionType {
	PERCENT = 'PERCENT',
	AMOUNT = 'AMOUNT',
}


export enum RunWasteType {
	WEIGHT = 'WEIGHT',
	VOLUME = 'VOLUME',
}
