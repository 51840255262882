export const environment = {
	env: 'TESTING',
	production: false,
	app: {
		api: {
			url: 'https://v1.api.dev.ripeurs.net/',
		},
		apiV2: {
			url: 'https://v2.api.testing.ripeurs.net/',
		},
		urls: {
			files: 'https://files.testing.ripeurs.net/',
			assets: 'https://assets.ripeurs.net/',
		},
		tva: 1.2,
		signature: {
			width: 280,
			height: 140,
			scale: 2, /* 560 x 280 */
		},
	},
};
