import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'highlight' })
export class RipHighlightPipe implements PipeTransform {
	public transform(value: string, search: string, eleClass: string = 'rip-highlight') {
		const searchs = search.split(' ');
		for (const word of searchs) {
			value = value.replace(
				new RegExp('(' + word.replace(/([^a-z0-9])/ig, '\\$1') + ')', 'ig'),
				'<span class="' + eleClass + '">$1</span>',
			);
		}
		return value;
	}
}
