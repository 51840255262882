import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({ name: 'sanitizeUrl' })
export class SanitizeUrlPipe implements PipeTransform {
	constructor(private readonly _domSanitizer: DomSanitizer) {}

	public transform(url: string) {
		return this._domSanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
