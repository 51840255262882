<h2 mat-dialog-title>
	Signature du reçu
</h2>
<mat-dialog-content>
	<canvas
		#canvas
		(mousedown)="paintMousedown($event)"
		(mousemove)="paintMousemove($event)"
		(mouseup)="paintMouseup($event)"
		(touchcancel)="paintMouseup($event)"
		(touchend)="paintMouseup($event)"
		(touchmove)="paintMousemove($event)"
		(touchstart)="paintMousedown($event)"
		class="signature"
	></canvas>
</mat-dialog-content>
<mat-dialog-actions align="center">
	<button
		(click)="valid()"
		[disabled]="paintPointCount < 10"
		cdkFocusInitial
		class="valid"
		color="primary"
		mat-flat-button
	>
		{{ 'Valider' }}
	</button>
	<button
		(click)="paintClear()"
		class="clear"
		mat-flat-button
	>
		{{ 'Recommencer' }}
	</button>
	<button
		(click)="cancel()"
		class="cancel"
		mat-flat-button
	>
		{{ 'Annuler' }}
	</button>
</mat-dialog-actions>
