import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';

import { TranslateModule } from '@ngx-translate/core';

import { CommonDialogMessage } from './dialog/message/message.component';
import { CommonDialogSignatureComponent } from './dialog/signature/signature.component';
import { ReplacePipe } from './pipe/replace.pipe';
import { RipDatePipe } from './pipe/rip-date.pipe';
import { RipHighlightPipe } from './pipe/rip-highlight.pipe';
import { RipKeyInArrayPipe } from './pipe/rip-keyinarray.pipe';
import { RipLuxonDurationPipe } from './pipe/rip-luxon-duration.pipe';

import { RipMinute2timePipe } from './pipe/rip-minute2time.pipe';
import { RipTextgroupPipe } from './pipe/rip-textgroup.pipe';
import { SanitizeHtmlPipe } from './pipe/sanitize-html.pipe';
import { SanitizeUrlPipe } from './pipe/sanitize-url.pipe';


const modules = [
	AngularCommonModule,
	FormsModule,
	ReactiveFormsModule,

	MatAutocompleteModule,
	MatButtonModule,
	MatButtonToggleModule,
	MatCardModule,
	MatCheckboxModule,
	MatDialogModule,
	MatIconModule,
	MatInputModule,
	MatListModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatRippleModule,
	MatSelectModule,
	MatSidenavModule,
	MatStepperModule,
	MatToolbarModule,
	TranslateModule,
];
const pipes = [
	ReplacePipe,
	RipDatePipe,
	RipHighlightPipe,
	RipKeyInArrayPipe,
	RipLuxonDurationPipe,
	RipMinute2timePipe,
	RipTextgroupPipe,
	SanitizeHtmlPipe,
	SanitizeUrlPipe,
];
const components = [
	CommonDialogMessage,
	CommonDialogSignatureComponent,
];

@NgModule({
	imports: [...modules],
	exports: [...modules, ...pipes],
	declarations: [...pipes, ...components],
})
export class CommonModule {}
