import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { firstValueFrom, of, Subject } from 'rxjs';

import { DialogService } from '~app/services/dialog.service';
import { UserService } from '~app/services/user.service';


@Injectable({ providedIn: 'root' })
export class PermissionsService {
	constructor(
		private readonly _dialog: DialogService,
		private readonly _user: UserService,
	) {}

	private _onChange = new Subject<{
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	}>();

	public get onChange(): Subject<{
		next: ActivatedRouteSnapshot;
		state: RouterStateSnapshot
	}> { return this._onChange; }

	private _locked = false;

	public get locked(): boolean { return this._locked; }

	public set locked(value: boolean) { this._locked = value; }

	public isLocked() { return this.locked; }

	public async canActivate(currentUser: UserService, router: Router): Promise<boolean> {
		if (currentUser.isLogged()) return true;

		const token = currentUser.getToken();
		if (token && token !== 'null') {
			const isLogged = await this.getUserDataWithToken(token!);
			if (isLogged) return true;
		}

		of(router.navigate(['/user/login']));
		return false;
	}

	public async canNavigate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (this.locked) this._onChange.next({ next, state });
		return !this.locked;
	}

	public canNavigateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.canNavigate(next, state);
	}

	private async getUserDataWithToken(token: string): Promise<boolean> {
		const dialogLoginLoader = this._dialog.openMessage({
			title: 'Connexion...',
			contentLoader: true,
		});

		const isLogged = await firstValueFrom(this._user.loginToken(token));
		dialogLoginLoader.close();
		return isLogged;
	}
}
