<mat-card [@card]="anim.card">
	<mat-card-content>
		<form (ngSubmit)="loginSubmit()" [formGroup]="login.form">
			<mat-form-field appearance="fill">
				<mat-label>Adresse email</mat-label>
				<input
					autocomplete="email"
					formControlName="email"
					matInput
					type="email"
				>
			</mat-form-field>
			<mat-form-field appearance="fill">
				<mat-label>Mot de passe</mat-label>
				<input
					autocomplete="current-password"
					formControlName="password"
					matInput
					type="password"
				>
			</mat-form-field>
			@if (login.error) {
				<mat-error class="error">Vos identifiants sont incorrects.</mat-error>
			}
			<mat-card-actions>
				<button
					[disabled]="!login.form.valid"
					mat-flat-button
				>
					@if (login.loading) {
						<mat-spinner diameter="22"/>
					}
					Connexion
				</button>
			</mat-card-actions>
		</form>
	</mat-card-content>
</mat-card>
