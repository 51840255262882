export function LocalStorage(key: string) {
	const prefix = 'ripeurs-operator-app';
	return function (target: Object, propertyKey: string) {
		Object.defineProperty(target, propertyKey, {
			get: () => {
				const item = localStorage.getItem(`${prefix}_${key}`);
				try {
					return JSON.parse(item!);
				} catch (e) {
					return item;
				}
			},
			set: (value: string) => localStorage.setItem(`${prefix}_${key}`, JSON.stringify(value)),
			enumerable: true,
			configurable: true,
		});
	};
}
