import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { of, take } from 'rxjs';

import { cardAnimation } from '~app/common/animation/card.animation';
import { UserService } from '~app/services/user.service';

import { UserForm } from '../user.form';


@Component({
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	animations: [cardAnimation],
})
export class UserLoginComponent implements OnInit {
	public login = {
		form: (new UserForm).login(),
		loading: false,
		error: false,
	};
	public anim = { card: 'close' };

	constructor(
		private readonly _router: Router,
		private readonly _user: UserService,
	) {}

	public ngOnInit(): void {
		if (this._user.isLogged()) of(this._router.navigate(['/']));
		setTimeout(() => { this.anim.card = 'open'; }, 500);
	}

	public loginSubmit() {
		if (!this.login.form.valid) return;
		this.login.form.disable();
		this.login.loading = true;
		this.login.error = false;
		this._user.login(
			this.login.form.controls.email.value,
			this.login.form.controls.password.value,
		).pipe(take(1)).subscribe({
			next: (logged) => {
				if (logged) {
					this.anim.card = 'close';
					of(this._router.navigate(['/user/dashboard']));
				} else {
					this.login.loading = false;
					this.login.error = true;
					this.login.form.enable();
				}
			},
			error: () => {
				this.login.loading = false;
				this.login.error = true;
				this.login.form.enable();
			},
		});
	}
}
