import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { DateTime } from 'luxon';

import { UserService } from '~app/services/user.service';


@Pipe({ name: 'ripDate' })
export class RipDatePipe extends DatePipe implements PipeTransform {
	constructor(private readonly _user: UserService) {
		super('en-GB');
	}

	public transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string;
	public transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
	public transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null {
		if (value === null || value === undefined) return null;

		const { timezone: tz, locale: loc } = this.getTimezoneAndLocale(value, timezone, locale);

		return super.transform(value, format, tz, loc);
	}

	private getTimezoneAndLocale(value: any, timezone?: string, locale?: string): { timezone?: string, locale?: string } {
		const region = this._user.getRegion();

		if (!region) return { timezone, locale };

		const date = value instanceof Date ?
			DateTime.fromJSDate(value, { zone: 'UTC' }) :
			DateTime.fromISO(value, { zone: 'UTC' });

		timezone = timezone ?? date.setZone(region.config.timezone).toFormat('ZZZ');
		locale = locale ?? region.config.locale;

		return { timezone, locale };
	}
}
