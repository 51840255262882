import { animate, state, style, transition, trigger } from '@angular/animations';


export const cardAnimation = trigger('card', [
	state('open', style({
		opacity: 1.0,
		transform: 'translateY(0px)',
	})),
	state('close', style({
		opacity: 0.0,
		transform: 'translateY(-100px)',
	})),
	transition('open=>close', [animate('300ms')]),
	transition('close=>open', [animate('300ms')]),
]);
