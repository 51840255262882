import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'ripKeyInArray' })
export class RipKeyInArrayPipe implements PipeTransform {
	public transform(search: string, array: any[], keyKey: string = 'key', keyValue: string = 'name') {
		for (const a of array) {
			if (a[keyKey] === search) {
				return a[keyValue];
			}
		}
		return null;
	}
}
