<div
	*ngIf="data !== null"
	[class.mode-iframe]="data.contentIframeUrl !== undefined"
	class="main"
>
	<div
		*ngIf="data.debug !== undefined || data.close !== undefined"
		class="debug"
	>
		<mat-icon
			(click)="debugClick()"
			*ngIf="data.debug !== undefined"
		>bug_report
		</mat-icon>
		<mat-icon
			(click)="close()"
			*ngIf="data.close !== undefined"
		>close
		</mat-icon>
	</div>
	<h2
		*ngIf="data.title !== undefined"
		mat-dialog-title
	>
		{{ data.title }}
	</h2>
	<mat-dialog-content>
		<iframe
			*ngIf="data.contentIframeUrl !== undefined"
			[src]="data.contentIframeUrl|sanitizeUrl"
		></iframe>
		<div
			*ngIf="data.content !== undefined"
			[innerHTML]="data.content|sanitizeHtml"
			class="content"
		></div>
		<div
			*ngIf="data.debugId !== undefined"
			[innerHTML]="data.debugId|sanitizeHtml"
			class="debugId"
		></div>
		<div
			*ngIf="data.contentLoader === true"
			class="loader"
		>
			<mat-spinner
				color="primary"
				mode="indeterminate"
			></mat-spinner>
		</div>
		<mat-icon
			*ngIf="data.contentIcon !== undefined"
			class="icon"
			color="primary"
		>{{ data.contentIcon }}
		</mat-icon>
	</mat-dialog-content>
	<mat-dialog-actions
		*ngIf="data.buttons?.length > 0"
		[class.inline]="data.buttons?.length <= 2"
		align="end"
		class="buttons"
	>
		<button
			(click)="buttonClick(button)"
			*ngFor="let button of data.buttons"
			class="button"
			mat-button
		>
			<mat-icon
				*ngIf="button.iconLeft !== undefined"
				class="icon-left"
			>
				{{ button.iconLeft }}
			</mat-icon>
			{{ button.title }}
			<mat-icon
				*ngIf="button.iconRight !== undefined"
				class="icon-right"
			>
				{{ button.iconRight }}
			</mat-icon>
		</button>
	</mat-dialog-actions>
</div>
