import { Injectable } from '@angular/core';
import { RegionType } from '~type/service/geo/region';

import { OperationType } from '~type/service/utility/operation';

import {
	landfills,
	metaRegions,
	materialHandlers,
	operationSteps,
	OperationStepsType,
	providerNames,
	RegionsSimulator,
	simulatorByZone,
	trucks,
} from './constants/ut.constants';

@Injectable({ providedIn: 'root' })
export class UtService {
	private readonly metaRegions: RegionType.Region[] = metaRegions;
	private readonly materialHandlers = materialHandlers;
	private readonly trucks = trucks;
	private readonly landfills = landfills;
	private readonly providerNames = providerNames;
	private readonly operationSteps: OperationStepsType = operationSteps;
	private readonly simulatorByZone: RegionsSimulator = simulatorByZone;

	public getTrucks(keyValue: boolean = false) {
		if (keyValue) return this.trucks.map((name) => ({ key: name.trim(), name: name.trim() }));
		return this.trucks;
	}

	public getLandfills(keyValue: boolean = false) {
		if (keyValue) return this.landfills.map(([name, type]) => ({ key: name.trim(), name: name.trim(), type }));
		return this.landfills;
	}

	public getMaterialHandlers(keyValue: boolean = false) {
		if (keyValue) return this.materialHandlers.map((name) => ({ key: name.trim(), name: name.trim() }));
		return this.materialHandlers;
	}

	public getProviderNames(keyValue: boolean = false) {
		if (keyValue) return this.providerNames.map((name) => ({ key: name.trim(), name: name.trim() }));
		return this.providerNames;
	}

	public getOperationSteps(): { status: OperationType.Status }[] {
		return OperationType.STATUS_SORTING.map((status) => ({
			...this.operationSteps[status],
			status: status,
		}));
	}

	public getSimulatorByZone(): RegionsSimulator { return this.simulatorByZone; }

	public getMetaRegions(): RegionType.Region[] { return this.metaRegions; }
}
