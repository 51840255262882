import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { environment } from 'src/environments/environment';

interface PaintType {
	width: number;
	height: number;
	scale: number;
	export: string;
	context: CanvasRenderingContext2D | null;
	isDraw: boolean;
	lastPoint: number[] | null;
}

@Component({
	templateUrl: './signature.component.html',
	styleUrls: ['./signature.component.scss'],
})
export class CommonDialogSignatureComponent implements AfterViewInit {
	public paintPointCount = 0;
	private paint: PaintType = {
		width: environment.app.signature.width,
		height: environment.app.signature.height,
		scale: environment.app.signature.scale,
		export: 'image/png',
		context: null,
		isDraw: false,
		lastPoint: null,
	};
	@ViewChild('canvas', { static: true })
	private readonly canvasEle: ElementRef | any;

	constructor(
		private readonly dialogRef: MatDialogRef<CommonDialogSignatureComponent>,
	) {}

	public ngAfterViewInit() {
		this.paint.context = this.canvasEle.nativeElement.getContext('2d');
		this.paint.context!.canvas.width = this.paint.width * this.paint.scale;
		this.paint.context!.canvas.height = this.paint.height * this.paint.scale;
	}

	public paintClear() {
		console.log({ paintPointCount: this.paintPointCount });
		this.paint.context!.clearRect(0, 0, this.paint.context!.canvas.width, this.paint.context!.canvas.height);
		this.paintPointCount = 0;
	}

	public paintMousedown(e: TouchEvent | MouseEvent) {
		this.paint.isDraw = true;
		this.paintMousemove(e);
	}

	public paintMousemove(e: any) {
		if (!this.paint.isDraw) { return; }
		const compensator = this.canvasEle.nativeElement.getBoundingClientRect();
		const x = ((e.pageX || e.changedTouches[0].pageX || 0) - compensator.x) * this.paint.scale * (this.paint.width / compensator.width);
		const y = ((e.pageY || e.changedTouches[0].pageY || 0) - compensator.y) * this.paint.scale * (this.paint.height / compensator.height);
		if (this.paint.lastPoint === null) {
			this.paint.lastPoint = [x - 1, y - 1];
		}
		this.paint.context!.beginPath();
		this.paint.context!.lineCap = 'round';
		this.paint.context!.moveTo(this.paint.lastPoint[0], this.paint.lastPoint[1]);
		const delta = this.calcPythagorean(this.paint.lastPoint!, [x, y]) || 1;
		let lineWidth = Math.round((3 - Math.round(delta / 25 * 10) / 10) * this.paint.scale);
		if (lineWidth < this.paint.scale) { lineWidth = this.paint.scale; }
		if (lineWidth > this.paint.lastPoint![2] + 1) {
			lineWidth = this.paint.lastPoint![2] + 1;
		} else if (lineWidth < this.paint.lastPoint![2] - 1) {
			lineWidth = this.paint.lastPoint![2] - 1;
		}
		this.paint.context!.lineWidth = lineWidth;
		this.paint.context!.lineTo(x, y);
		this.paint.context!.stroke();
		this.paint.lastPoint = [x, y, lineWidth];
		this.paintPointCount++;
	}

	public paintMouseup(e: any) {
		this.paint.isDraw = false;
		this.paint.lastPoint = null;
	}

	public valid() {
		if (this.paintPointCount < 10) { return; }
		this.dialogRef.close({
			signature: this.paintExport(),
		});
	}

	public cancel() {
		this.dialogRef.close({
			signature: null,
		});
	}

	private calcPythagorean(
		pointA: number[],
		pointB: [number, number],
	) {
		return Math.sqrt(Math.pow(pointA[0] - pointB[0], 2) + Math.pow(pointA[1] - pointB[1], 2));
	}

	private paintExport() {
		return this.canvasEle.nativeElement.toDataURL(this.paint.export);
	}
}
